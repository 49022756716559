// extracted by mini-css-extract-plugin
export var mainIndexContainer = "index-module--mainIndexContainer--1-iH-";
export var linkButton = "index-module--linkButton--29myb";
export var link = "index-module--link--1Un0D";
export var linkContainer = "index-module--linkContainer--c7Tmt";
export var greenTxt = "index-module--greenTxt--2BFkp";
export var bottomTxt = "index-module--bottomTxt--1Fvuj";
export var blueBtn = "index-module--blueBtn--1_3uM";
export var topContainer = "index-module--topContainer--mUsqj";
export var bgContainer = "index-module--bgContainer--2IvVA";
export var imgContainer = "index-module--imgContainer--2yvZ2";
export var bgImg = "index-module--bgImg--3uEmg";
export var opacity = "index-module--opacity--3pIpm";
export var headerTexts = "index-module--headerTexts--3BNTW";
export var secondaryTexts = "index-module--secondaryTexts--p20tr";
export var secondContainer = "index-module--secondContainer--YkwcS";
export var thirdContainer = "index-module--thirdContainer--2yvrm";
export var bottomTxtThird = "index-module--bottomTxtThird--QzbJr";
export var scholarshipsContainer = "index-module--scholarshipsContainer--3NuL7";
export var scholarship = "index-module--scholarship--39gnG";
export var imageContainer = "index-module--imageContainer--jIsCT";
export var image = "index-module--image--2THIp";
export var fourthContainer = "index-module--fourthContainer--2uwwi";
export var content = "index-module--content--ZXwgT";
export var peopleContainer = "index-module--peopleContainer--ns7zN";
export var people = "index-module--people--2mck1";
export var peopleIcon = "index-module--peopleIcon--JSmqM";
export var links = "index-module--links--2-l3q";
export var divider = "index-module--divider--39MPr";
export var icon = "index-module--icon--3YLhn";
export var fifthContainer = "index-module--fifthContainer--2o1r_";
export var studentsContainer = "index-module--studentsContainer--2OBuo";
export var student = "index-module--student--2ixeO";
export var studentLong = "index-module--studentLong--3wOp4";
export var texts = "index-module--texts--3bCcp";
export var buttons = "index-module--buttons--_nC9X";
export var indicator = "index-module--indicator--1hg_i";
export var title = "index-module--title--1XGBb";
export var button = "index-module--button--2YDWh";
export var left = "index-module--left--1Jt7l";
export var circle = "index-module--circle--2eZ2E";
export var active = "index-module--active--3sAEq";