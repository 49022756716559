import React, { useState } from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import * as styles from './CompanySlider.module.scss';
import { BsChevronCompactLeft } from '@react-icons/all-files/bs/BsChevronCompactLeft';

const CompanySlider = ({ screen }) => {
    const [rightImage, setRightImage] = useState(2);

    const moveImg = (btn) => {
        const container = document.getElementById('companiesSlider');

        if (btn === 'next') {
            if (screen === 'xl') {
                container.scrollLeft += 290;
            } else if (screen === 'large') {
                container.scrollLeft += 230;
            } else if (screen === 'small') {
                container.scrollLeft += 151;
            } else {
                container.scrollLeft += 129;
            }
        } else {
            if (screen === 'xl') {
                container.scrollLeft -= 290;
            } else if (screen === 'large') {
                container.scrollLeft -= 230;
            } else if (screen === 'small') {
                container.scrollLeft -= 151;
            } else {
                container.scrollLeft -= 129;
            }
        }
    };

    return (
        <div className={styles.companySliderContainer}>
            <div className={styles.chevronContainer}>
                <BsChevronCompactLeft
                    className={styles.chevron}
                    onClick={() => moveImg('prev')}
                />
            </div>

            <div className={styles.companies} id="companiesSlider">
                <div className={styles.imageContainer}>
                    <StaticImage
                        src="../images/index-partner-1.png"
                        alt="companies"
                        placeholder="blurred"
                        className={styles.image}
                        loading="eager"
                    />
                </div>

                <div className={styles.imageContainer}>
                    <StaticImage
                        src="../images/index-partner-2.png"
                        alt="companies"
                        placeholder="blurred"
                        className={styles.image}
                        loading="eager"
                    />
                </div>

                <div className={styles.imageContainer}>
                    <StaticImage
                        src="../images/index-partner-3.png"
                        alt="companies"
                        placeholder="blurred"
                        className={styles.image}
                        loading="eager"
                    />
                </div>

                <div className={styles.imageContainer}>
                    <StaticImage
                        src="../images/index-partner-4.png"
                        alt="companies"
                        placeholder="blurred"
                        className={styles.image}
                        loading="eager"
                    />
                </div>

                <div className={styles.imageContainer}>
                    <StaticImage
                        src="../images/index-partner-5.png"
                        alt="companies"
                        placeholder="blurred"
                        className={styles.image}
                        loading="eager"
                    />
                </div>
            </div>

            <div className={styles.chevronContainer}>
                <BsChevronCompactLeft
                    className={`${styles.chevron} ${styles.right}`}
                    onClick={() => moveImg('next')}
                />
            </div>
        </div>
    );
};

export default CompanySlider;
