import React, { useState, useEffect } from 'react';
import { Link, graphql } from 'gatsby';
import * as styles from './index.module.scss';
import Layout from '../components/Layout';
import { StaticImage, GatsbyImage, getImage } from 'gatsby-plugin-image';
import { FaChevronRight } from '@react-icons/all-files/fa/FaChevronRight';
import Fade from 'react-reveal/Fade';
import { HiArrowNarrowRight } from '@react-icons/all-files/hi/HiArrowNarrowRight';
import CompanySlider from '../components/CompanySlider';

export default function Home({ data }) {
    const [imgSlider, setImgSlider] = useState(1);
    const [screen, setScreen] = useState(null);
    const [hasRan, setHasRan] = useState(false);
    const programmesData = data.allProgrammesJson.nodes;

    const moveSlider = (btn) => {
        const container = document.getElementById('indexStudentsContainer');

        if (btn === 'next') {
            if (imgSlider < 4) {
                var newVal = imgSlider + 1;
                setImgSlider(newVal);
                container.scrollLeft += window.innerWidth * 0.9;
            }
        } else {
            if (imgSlider > 1) {
                var newVal = imgSlider - 1;
                setImgSlider(newVal);
                container.scrollLeft -= window.innerWidth * 0.9;
            }
        }
    };

    const updateScreenSize = () => {
        setScreen(getScreenSize());
    };

    const getScreenSize = () => {
        const width = window.innerWidth;

        if (width >= 1300) {
            return 'xl';
        } else if (width >= 992 && width < 1300) {
            return 'large';
        } else if (width >= 600 && width < 992) {
            return 'small';
        } else {
            return 'xs';
        }
    };

    const handleResize = () => {
        setScreen(getScreenSize());
    };

    useEffect(() => {
        if (!hasRan) {
            setHasRan(true);
            updateScreenSize();
        }
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [getScreenSize]);

    return (
        <Layout darkBg={true}>
            <div className={styles.mainIndexContainer}>
                <div className={styles.topContainer}>
                    <div className={styles.bgContainer}>
                        <div className={styles.imgContainer}>
                            <StaticImage
                                alt="Background Image"
                                src="../images/index-hero.jpg"
                                placeholder="blurred"
                                className={styles.bgImg}
                            />
                        </div>

                        <div className={styles.opacity}></div>

                        <div className={styles.headerTexts}>
                            <Fade right distance="50px" duration={900}>
                                <h1>
                                    Empowering Communities{' '}
                                    {screen === 'xs' ? null : <br />}
                                    Through Tech Education
                                </h1>
                            </Fade>

                            <Fade
                                right
                                distance="50px"
                                duration={900}
                                delay={100}
                            >
                                <div className={styles.secondaryTexts}>
                                    <p>
                                        Digital Society Fund (DSF) is a social
                                        initiative with a mission to create
                                        pathways to educational and professional
                                        success in technology for the
                                        underprivileged.
                                    </p>
                                </div>
                            </Fade>
                        </div>
                    </div>
                </div>

                <div className={styles.secondContainer}>
                    <CompanySlider screen={screen} />
                </div>

                <div className={styles.thirdContainer}>
                    <Fade bottom distance="50px">
                        <p className={styles.greenTxt}>OUR INITIATIVE</p>
                    </Fade>

                    <Fade bottom distance="50px" delay={100}>
                        {screen === 'xs' ? (
                            <h5>
                                Democratizing Technological Education For All
                            </h5>
                        ) : (
                            <h5>
                                Closing the gap of technological education for
                                the underprivileged
                            </h5>
                        )}
                    </Fade>

                    <Fade bottom distance="50px" delay={150}>
                        {screen === 'xs' ? (
                            ''
                        ) : (
                            <p className={styles.bottomTxtThird}>
                                Our mission is to empower the underprivileged
                                communities in technology through affordable
                                education, quality instruction, and
                                individualized mentorship. We offer a wide range
                                of programmes to include people of all ages,
                                including scholarships for undergraduate
                                education, professional certifications, and
                                more.
                            </p>
                        )}
                    </Fade>

                    <Fade bottom distance="50px">
                        <div className={styles.scholarshipsContainer}>
                            {programmesData.map((programme) => {
                                return (
                                    <div className={styles.scholarship}>
                                        <Link
                                            className={styles.linkContainer}
                                            to={programme.link}
                                        >
                                            <div
                                                className={
                                                    styles.imageContainer
                                                }
                                            >
                                                <GatsbyImage
                                                    image={getImage(
                                                        programme.imgUrl
                                                    )}
                                                    alt={programme.title}
                                                    imgStyle={
                                                        screen === 'small'
                                                            ? {
                                                                  borderBottomLeftRadius:
                                                                      '10px',
                                                                  borderTopLeftRadius:
                                                                      '10px'
                                                              }
                                                            : {
                                                                  borderTopRightRadius:
                                                                      '10px',
                                                                  borderTopLeftRadius:
                                                                      '10px'
                                                              }
                                                    }
                                                    className={styles.image}
                                                />
                                            </div>
                                            <span>{programme.level}</span>
                                            <h6>{programme.title}</h6>
                                            <Link
                                                className={styles.link}
                                                to={programme.link}
                                            >
                                                Learn more
                                                <HiArrowNarrowRight
                                                    className={styles.icon}
                                                />
                                            </Link>
                                        </Link>
                                    </div>
                                );
                            })}
                        </div>

                        <Link className={styles.linkButton} to="/scholarships">
                            <div className={styles.blueBtn}>
                                Explore All Scholarships
                            </div>
                        </Link>
                    </Fade>
                </div>

                <div className={styles.fourthContainer}>
                    <div className={styles.bgContainer}>
                        <div className={styles.content}>
                            <Fade bottom distance="50px">
                                <p className={styles.greenTxt}>
                                    OUR PROGRAMMES
                                </p>
                            </Fade>

                            <Fade bottom distance="50px" delay={100}>
                                {screen === 'xs' ? (
                                    <h5>Find Out What's For You</h5>
                                ) : (
                                    <h5>
                                        Find out how Digital Society Fund can
                                        help you
                                    </h5>
                                )}
                            </Fade>

                            <Fade
                                bottom
                                distance="50px"
                                delay={screen === 'xs' ? 0 : 100}
                            >
                                <div className={styles.peopleContainer}>
                                    <div className={styles.people}>
                                        <div className={styles.title}>
                                            <div className={styles.peopleIcon}>
                                                <StaticImage
                                                    src="../images/index-help-1.png"
                                                    alt="For Individuals"
                                                    placeholder="blurred"
                                                />
                                            </div>
                                            <h6>For Individuals</h6>
                                        </div>
                                        <div className={styles.texts}>
                                            <p>
                                                Apply for a sponsorship for your
                                                undergraduate tech education, or
                                                sign up for our professional
                                                certification course.
                                            </p>

                                            <div className={styles.links}>
                                                <Link
                                                    to="/scholarship/futuremakers-scholarship"
                                                    className={`${styles.link} ${styles.firstLink}`}
                                                >
                                                    Undergrad scholarships
                                                    <HiArrowNarrowRight
                                                        className={styles.icon}
                                                    />
                                                </Link>

                                                <div
                                                    className={styles.divider}
                                                ></div>

                                                <Link
                                                    className={styles.link}
                                                    to="/scholarship/professional-certifications"
                                                >
                                                    Professional certifications
                                                    <HiArrowNarrowRight
                                                        className={styles.icon}
                                                    />
                                                </Link>
                                            </div>
                                        </div>
                                    </div>

                                    <div className={styles.people}>
                                        <div className={styles.title}>
                                            <div className={styles.peopleIcon}>
                                                <StaticImage
                                                    src="../images/index-help-2.png"
                                                    alt="For Education Providers"
                                                    placeholder="blurred"
                                                />
                                            </div>
                                            <h6>For Education Providers</h6>
                                        </div>
                                        <div className={styles.texts}>
                                            <p>
                                                Partner with us as a course
                                                provider, or include your school
                                                in our STEM Focused K-12
                                                Development Programme.
                                            </p>

                                            <div className={styles.links}>
                                                <Link
                                                    className={`${styles.link} ${styles.firstLink}`}
                                                    to="/programme/k-12-development-programme"
                                                >
                                                    Include your school
                                                    <HiArrowNarrowRight
                                                        className={styles.icon}
                                                    />
                                                </Link>

                                                <div
                                                    className={styles.divider}
                                                ></div>

                                                <Link
                                                    className={styles.link}
                                                    to="/contact"
                                                    state={{
                                                        message:
                                                            'Subject: Interested in Joining as a Course Provider'
                                                    }}
                                                >
                                                    Be a course provider
                                                    <HiArrowNarrowRight
                                                        className={styles.icon}
                                                    />
                                                </Link>
                                            </div>
                                        </div>
                                    </div>

                                    <div className={styles.people}>
                                        <div className={styles.title}>
                                            <div className={styles.peopleIcon}>
                                                <StaticImage
                                                    src="../images/index-help-3.png"
                                                    alt="For Companies"
                                                    placeholder="blurred"
                                                />
                                            </div>
                                            <h6>For Companies</h6>
                                        </div>
                                        <div className={styles.texts}>
                                            <p>
                                                Join our family of corporate
                                                sponsors, or contact us to
                                                explore how you can optimise
                                                your workforce.
                                            </p>

                                            <div className={styles.links}>
                                                <Link
                                                    className={`${styles.link} ${styles.firstLink}`}
                                                    to="/scholarship/professional-certifications"
                                                >
                                                    Optimise your workforce
                                                    <HiArrowNarrowRight
                                                        className={styles.icon}
                                                    />
                                                </Link>

                                                <div
                                                    className={styles.divider}
                                                ></div>

                                                <Link
                                                    className={styles.link}
                                                    to="/contact"
                                                    state={{
                                                        message:
                                                            'Subject: Interested in Being a Corporate Sponsor'
                                                    }}
                                                >
                                                    Be a corporate sponsor
                                                    <HiArrowNarrowRight
                                                        className={styles.icon}
                                                    />
                                                </Link>
                                            </div>
                                        </div>
                                    </div>

                                    <div className={styles.people}>
                                        <div className={styles.title}>
                                            <div className={styles.peopleIcon}>
                                                <StaticImage
                                                    src="../images/index-help-4.png"
                                                    alt="For Governments"
                                                    placeholder="blurred"
                                                />
                                            </div>
                                            <h6>For Public Sectors</h6>
                                        </div>
                                        <div className={styles.texts}>
                                            <p>
                                                Be a part of the cause to bridge
                                                the digital divide, or contact
                                                us to fiind out how to transform
                                                your public sector.
                                            </p>

                                            <div className={styles.links}>
                                                <Link
                                                    className={`${styles.link} ${styles.firstLink}`}
                                                    to="/contact"
                                                    state={{
                                                        message:
                                                            'Subject: Bridging the Digital Divide in My Community'
                                                    }}
                                                >
                                                    Bridge digital divide
                                                    <HiArrowNarrowRight
                                                        className={styles.icon}
                                                    />
                                                </Link>

                                                <div
                                                    className={styles.divider}
                                                ></div>

                                                <Link
                                                    className={styles.link}
                                                    to="/contact"
                                                    state={{
                                                        message:
                                                            'Subject: Transforming the Public Sector'
                                                    }}
                                                >
                                                    Transform the public sector
                                                    <HiArrowNarrowRight
                                                        className={styles.icon}
                                                    />
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Fade>
                        </div>
                    </div>
                </div>

                <div className={styles.fifthContainer}>
                    <p className={styles.greenTxt}>OUR PEOPLE</p>

                    {screen === 'xs' || screen === 'small' ? (
                        <h5>Hear Our Success Stories</h5>
                    ) : (
                        <h5>
                            Hear what our beneficiaries have to say in their
                            success stories
                        </h5>
                    )}

                    <div
                        className={styles.studentsContainer}
                        id="indexStudentsContainer"
                    >
                        <div className={styles.studentLong}>
                            <div className={styles.imageContainer}>
                                <StaticImage
                                    src="../images/index-success-1.jpg"
                                    alt="Futuremakers"
                                    placeholder="blurred"
                                    className={styles.image}
                                    style={{ borderRadius: '5px' }}
                                    imgStyle={{ borderRadius: '5px' }}
                                />
                            </div>

                            <div className={styles.opacity}></div>

                            <div className={styles.texts}>
                                <h5>
                                    "I got a scholarship to study Computer
                                    Science!"
                                </h5>

                                <p>
                                    As someone who grew up in a low-income
                                    household, the sheer amount of financial
                                    freedom the scholarship has provided is life
                                    changing. I was able to focus solely on my
                                    undergraduate studies and chase my dream of
                                    becoming a programmer.
                                </p>

                                <span>- Thas</span>
                            </div>
                        </div>

                        <div className={styles.student}>
                            <div className={styles.imageContainer}>
                                <StaticImage
                                    src="../images/index-success-2.jpeg"
                                    alt="Futuremakers"
                                    placeholder="blurred"
                                    className={styles.image}
                                    style={{ borderRadius: '5px' }}
                                    imgStyle={{ borderRadius: '5px' }}
                                />
                            </div>

                            <div className={styles.opacity}></div>

                            <div className={styles.texts}>
                                <h5>"I'm a software developer!"</h5>

                                <p>
                                    I lost my job during Covid-19. DSF helped me
                                    to reskill and I successfully secured a job
                                    as a Web Developer.
                                </p>

                                <span>- Ariff</span>
                            </div>
                        </div>

                        <div className={styles.student}>
                            <div className={styles.imageContainer}>
                                <StaticImage
                                    src="../images/index-success-3.jpg"
                                    alt="Futuremakers"
                                    placeholder="blurred"
                                    style={{ borderRadius: '5px' }}
                                    imgStyle={{ borderRadius: '5px' }}
                                    className={styles.image}
                                />
                            </div>

                            <div className={styles.opacity}></div>

                            <div className={styles.texts}>
                                <h5>"We are inspired!"</h5>

                                <p>
                                    We are inspired to launch a career in
                                    technology after a DSF STEM workshop session
                                    in our school.
                                </p>

                                <span>- SMK Mantin students</span>
                            </div>
                        </div>

                        <div className={styles.studentLong}>
                            <div className={styles.imageContainer}>
                                <StaticImage
                                    src="../images/index-success-4.jpg"
                                    alt="Futuremakers"
                                    placeholder="blurred"
                                    imgStyle={{ borderRadius: '5px' }}
                                    style={{ borderRadius: '5px' }}
                                    className={styles.image}
                                />
                            </div>

                            <div className={styles.opacity}></div>

                            <div className={styles.texts}>
                                <h5>"I got promoted after I got certified."</h5>

                                <p>
                                    I got a sponsorship from my company for a
                                    6-months Data Analytics course. I applied
                                    the skills I have learnt and improved my
                                    department’s operation significantly. I
                                    managed to get promoted 6 months after I got
                                    certified!
                                </p>

                                <span>- Hani</span>
                            </div>
                        </div>

                        <div className={styles.buttons}>
                            <div
                                className={styles.button}
                                onClick={() => moveSlider('prev')}
                            >
                                <FaChevronRight
                                    className={`${styles.icon} ${styles.left}`}
                                />
                            </div>
                            <div
                                className={styles.button}
                                onClick={() => moveSlider('next')}
                            >
                                <FaChevronRight className={styles.icon} />
                            </div>
                        </div>

                        <div className={styles.indicator}>
                            <div
                                className={`${styles.circle} ${
                                    imgSlider === 1 ? styles.active : null
                                }`}
                            ></div>

                            <div
                                className={`${styles.circle} ${
                                    imgSlider === 2 ? styles.active : null
                                }`}
                            ></div>

                            <div
                                className={`${styles.circle} ${
                                    imgSlider === 3 ? styles.active : null
                                }`}
                            ></div>

                            <div
                                className={`${styles.circle} ${
                                    imgSlider === 4 ? styles.active : null
                                }`}
                            ></div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
}

export const ProgrammesQuery = graphql`
    query {
        allProgrammesJson {
            nodes {
                id
                imgUrl {
                    childImageSharp {
                        gatsbyImageData(placeholder: BLURRED)
                    }
                }
                level
                title
                link
            }
        }
    }
`;
